import * as React from "react"
import SEO from "../components/SEO/SEO";
import { Layout } from "../components/layout/layout";
import { PageSection } from "../components/page-section/page-section";

import "./sales.scss";

import image1 from "../images/sales-1-small.png";
import image2 from "../images/sales-2-small.png";
import image3 from "../images/sales-3-small.png";
import image1WP from "../images/sales-1-small.webp";
import image2WP from "../images/sales-2-small.webp";
import image3WP from "../images/sales-3-small.webp";
import largeImage1 from "../images/sales-1.png";
import largeImage2 from "../images/sales-2.png";
import largeImage3 from "../images/sales-3.png";
import largeImage1WP from "../images/sales-1.webp";
import largeImage2WP from "../images/sales-2.webp";
import largeImage3WP from "../images/sales-3.webp";
import { Arrow } from "../components/arrow/arrow";
import FormContainer from "../components/form-container/form-container";

const SalesPage = () => {
  return (
    <main>
      <SEO
        title="Yatch Sales"
        pathname="/sales"
        description="We guide you in your path to be a boat owner"
        keywords={["sale", "buy", "brokerage", "yacht"]}
      />
      <Layout page="sales">
        <div className="my-sales">
          <div className="my-sales-space" />
          <Arrow alignBottom={false} url="brokerage" />
          <PageSection id="brokerage" image={image1} imageWP={image1WP} largeImage={largeImage1} largeImageWP={largeImage1WP} title="Brokerage" isReverse={true}>
            <p className="my-sales-section">We provide professional and confidential services in an honest effort to ensure that your purchase or selling process is efficient, timely and enjoyable.</p>
            <p className="my-sales-section">Whether it is a world cruising motor yacht, a quality trawler, a performance or cruising sailboat or a capable sport fishing yacht, our yachts consultants have a strong knowledge of current market values, construction materials, regulations and guidelines; proper yacht care and maintenance making us to be proud of being highly up-to-date on all aspects of the yachting industry.</p>
            <a className="my-sales__link" href="https://sales.miamiyachting.net">Search for a yatcht</a>
          </PageSection>
          <Arrow alignBottom={false} url="buying-yachts" />
          <PageSection id="buying-yachts" image={image2} imageWP={image2WP} largeImage={largeImage2} largeImageWP={largeImage2WP} title="Buying a Yacht">
            <p className="my-sales-section">Buying a yacht is one of the most important moments in the yachtsman’s life. It’s not different than researching and analyzing the real estate or stock markets when planning for a major investment.</p>
            <p className="my-sales-section">It’s the time when deciding factors like product & marketing knowledge, professionalism, aggressive negotiating tools and hard-earned reputation comes to play to maximize the return on our client’s investments.</p>
            <p className="my-sales-section">We have been aboard, delivered and sea-trialed hundreds of yachts of different kinds to get to know almost every other yacht. It’s time to use our professional brokerage services to work for you!</p>
            <a className="my-sales__link" href="https://sales.miamiyachting.net">Search for a yacht</a>

          </PageSection>
          <Arrow alignBottom={false} url="selling-yachts" />
          <PageSection id="selling-yachts" image={image3} imageWP={image3WP} largeImage={largeImage3} largeImageWP={largeImage3WP} title="Selling a Yacht" hasMargin={false} isReverse={true}>
            <p className="my-sales-section">As when offering our professional services when buying a yacht Miami Yachting brokerage implements all the same principles when our select clientele need to sell their yachts.</p>
            <p className="my-sales-section">A pleasure boat or yacht is way more than a toy. It’s our client’s dream. And it deserves to be treated with the same importance and respect that it has for our sellers.</p>
            <a className="my-sales__link" href="https://sales.miamiyachting.net">Search for a yacht</a>
            
          </PageSection>
          <div className="my-sales-space" />
        </div>
        <FormContainer topic="Brokerage" pagePath="sales" />
      </Layout>
    </main>
  )
}

export default SalesPage;
